<template>
  <v-dialog
    v-model="dialog"
    persistent
    overlay-color="black"
    overlay-opacity=".9"
    transition="scroll-x-transition"
    width="400px"
  >
    <v-card class="modal d-flex flex-column align-center justify-center">
      <spinner :is-loading="isLoading" class="mb-5" />
      <v-btn
        size="x-large"
        color="success"
        to="success"
        class="button"
        :disabled="isLoading"
        >Reciept</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
import Spinner from "./Spinner.vue";

export default {
  name: "ModalWindow",
  components: { Spinner },
  data() {
    return {
      dialog: false,
      isLoading: true,
    };
  },
  watch: {
    dialog() {
      if (this.dialog)
        setTimeout(() => {
          this.isLoading = false;
        }, 2000);
    },
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
    openModal() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  width: 100%;
  position: fixed;
  padding: 40px 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 16px !important;
  background: linear-gradient(
    110.42deg,
    rgba(251, 237, 241, 0.9) 2.17%,
    rgba(245, 243, 248, 0.9) 30.58%,
    rgba(249, 249, 249, 0.9) 54.01%,
    rgba(245, 243, 248, 0.9) 76.43%,
    rgba(251, 237, 241, 0.9) 100%
  );
}
.button {
  width: 90%;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.4;
  background-color: rgb(177, 73, 73) !important;
}
</style>
