<template>
  <div>
    <CardForm @send="$refs.modal.openModal()" />
    <ModalWindow ref="modal" />
  </div>
</template>

<script>
import CardForm from "./CardForm.vue";
import ModalWindow from "./ModalWindow.vue";
export default {
  name: "MainView",
  components: { CardForm, ModalWindow },
};
</script>
