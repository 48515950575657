export default {
  //   required: (value) =>
  //     typeof value === "number"
  //       ? !!String(value)
  //       : !!value || "Field is required",
  required: (value) => !!value || "Field is required",
  amount: (value) =>
    /^(100|[1-2][0-9]{2}|300)$/.test(value) || "Min 100 | Max 300",
  validCard: (value) =>
    /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/.test(value) || "Invalid card number",
  onlyLatinLetters: (value) =>
    !/[^A-Za-z ]/.test(value) || "Only latin letters",
};
