import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { createStore } from "vuex";
import { createRouter, createWebHashHistory } from "vue-router";
import Success from "./components/Success";
import MainView from "./components/MainView";

const store = createStore({
  state() {
    return {
      formData: null,
    };
  },
  mutations: {
    setData: (state, payload) => (state.formData = payload),
  },
  actions: {
    writeData: ({ commit }, data) => {
      commit("setData", data);
    },
  },
  getters: {
    getData: (state) => state.formData,
  },
});

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", name: "MainView", component: MainView },
    { path: "/success", name: "Success", component: Success },
  ],
});

const app = createApp(App);

app.use(vuetify);
app.use(store);
app.use(router);

app.mount("#app");
