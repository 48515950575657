<template>
  <v-app class="py-10">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "./assets/style.scss";
</style>
